import styled from "@emotion/styled";
import { compareDesc, format } from "date-fns";
import { KLAYO_COLORS } from "../../themes";
import { Table } from "./Table";

export class ProfileTrainingTable extends Table {
  static columns = {
    completedDate: {
      field: "completedDate",
      label: "COMPLETED"
    },
    courseName: {
      field: "courseName",
      label: "COURSE"
    },
    competencies: {
      field: "competencies",
      label: "COMPETENCIES"
    }
  };

  static defaultProps: any = {
    ...Table.defaultProps,
    columns: [
      { type: ProfileTrainingTable.columns.completedDate },
      { type: ProfileTrainingTable.columns.courseName },
      { type: ProfileTrainingTable.columns.competencies }
    ],
    noRowsMessage: null,
    rowHasAction: null
  };

  constructor(props: any) {
    super(props);
  }

  getRowId(e: any) {
    return this.tableId + "_" + e.id;
  }

  handleOnOpenAttributesDialog = (value: any, type: any) => {
    const { onOpenTaskAttributesDialog } = this.props;
    onOpenTaskAttributesDialog(value, type);
  };

  initColumns() {
    const { columns, sortable, showDialog } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === ProfileTrainingTable.columns.completedDate)
          this.columns.push({
            field: "completedDate",
            sortable: sortable,
            headerName: c.label || c.type.label,
            width: 120,
            disableReorder: true,
            valueGetter: (params: any) => format(new Date(params.row.completedDate), "dd-MMM-yyyy"),
            sortComparator: (d1: any, d2: any) => compareDesc(new Date(d2), new Date(d1))
          });
        if (c.type === ProfileTrainingTable.columns.courseName)
          this.columns.push({
            field: "courseName",
            sortable: sortable,
            headerName: c.label || c.type.label,
            disableReorder: true,
            flex: 1,
            filterable: false,
            valueGetter: (params: any) => params.row.courseName
          });
        if (c.type === ProfileTrainingTable.columns.competencies)
          this.columns.push({
            field: "competencies",
            sortable: sortable,
            width: 120,
            filterable: false,
            headerName: c.label || c.type.label,
            disableReorder: true,
            renderCell: (params: any) => {
              const competencies = params.row.associatedCompetencies;
              return (
                <div style={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}>
                  <KlayoTag
                    isTag={competencies.length !== 0}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      if (competencies.length > 0) showDialog(params.row);
                    }}
                  >
                    {competencies.length}
                  </KlayoTag>
                </div>
              );
            },
            valueGetter: (params: any) => params.row.associatedCompetencies.length
          });
      }
    });
  }
}

const KlayoTag = styled.div<any>`
  padding: 2px 12px;
  background-color: ${(props) =>
    props.isTag ? KLAYO_COLORS.NeonBlueNB050 : KLAYO_COLORS.transparent};
  border-radius: 100px;

  color: ${(props) => (props.isTag ? KLAYO_COLORS.NeonBlueNB500 : KLAYO_COLORS.GreyGR900)};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  :hover {
    filter: ${(props) => (props.isTag ? "brightness(95%)" : "")};
    cursor: ${(props) => (props.isTag ? "pointer" : "")};
  }

  :focus {
    background-color: ${(props) => (props.isTag ? "#c6c7fc" : "")};
  }
`;
