import { GridSortModel } from "@mui/x-data-grid";
import { cloneDeep, isEmpty } from "lodash";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { TeamFilterModel } from "../common/models/TeamFilterModel";
import { Utils } from "../common/Utils";
import { AbstractAttribute } from "../data/attributes/AbstractAttribute";
import { ITeamRegulationTaskResponse } from "../interfaces/Task";
import { ITeamRegulationTaskSummary } from "../interfaces/Team";
import { useTeamContext } from "../providers/TeamProvider";
import { appendIfUnique } from "../utilities";
import { useAppQuery } from "./useAppQuery";

type Props = {
  filter: TeamFilterModel;
  groupId?: string;
  basePath: string;
  basePathDepth: number;
  sortOrder?: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
};

const useTeamTaskSection = ({
  filter,
  groupId,
  basePath,
  basePathDepth,
  sortOrder,
  onSortOrderChange
}: Props) => {
  const { expandRowIds, setExpandRowIds, keywords, setKeywords } = useTeamContext();

  const tableRef = useRef<any>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "attribute", sort: sortOrder?.method || "asc" }
  ]);
  const history = useHistory();
  const location = useLocation();
  const taskPeopleListDialogRef = useRef<any>();

  const { gapMode, setGapMode } = useTeamContext();

  const isGapMode = useMemo(() => gapMode.tasks, [gapMode.tasks]);

  const filterParams = useMemo(() => {
    if (isEmpty(filter)) return "";

    const params = new URLSearchParams();

    filter.people.forEach((e) => appendIfUnique(params, "EmployeeIds", e.employeeId));
    filter.peopleIds.forEach((id) => appendIfUnique(params, "EmployeeIds", id));
    filter.jobs.forEach((j) => appendIfUnique(params, "Jobs", j));
    filter.locations.forEach((l) => appendIfUnique(params, "Locations", l));
    filter.attributes.forEach((a) =>
      appendIfUnique(params, "AttributeDefinitionIds", a.attributeDefinitionId)
    );
    filter.attributeIds.forEach((id) => appendIfUnique(params, "AttributeDefinitionIds", id));
    if (filter.compliance)
      appendIfUnique(
        params,
        "IsCompliant",
        (filter.compliance === AbstractAttribute.compliance.compliant) as any
      );
    if (filter.attributeType) appendIfUnique(params, "AttributeType", filter.attributeType.index);
    if (filter.expireAfter)
      appendIfUnique(params, "ExpiryDateFrom", Utils.toApiDate(filter.expireAfter) as any);
    if (filter.expireBefore)
      appendIfUnique(params, "ExpiryDateTo", Utils.toApiDate(filter.expireBefore) as any);

    return params.toString();
  }, [filter]);

  const { data, isLoading } = useAppQuery<ITeamRegulationTaskResponse>(
    `/${groupId ? `MyGroup/${groupId}` : "Teams"}/tasks?${filterParams}`
  );

  const tasks = useMemo(() => {
    if (!data) return [];

    return data?.teamRegulationTaskSummary?.map((task) => {
      const gapEmployees = task.gapEmployees.map((emp) => ({ ...emp, isCompliant: false }));
      const metEmployees = task.metEmployees.map((emp) => ({ ...emp, isCompliant: true }));

      return {
        ...task,
        gapEmployees,
        metEmployees,
        employees: [...gapEmployees, ...metEmployees]
      };
    });
  }, [data]);

  const classificationsSummary = useMemo(() => {
    if (!tasks?.length) return {};

    return tasks.reduce(
      (
        summary: { [key: string]: Partial<ITeamRegulationTaskSummary> },
        task: ITeamRegulationTaskSummary
      ) => {
        if ((isGapMode && !task.gapEmployees.length) || (!isGapMode && !task.metEmployees.length))
          return summary;

        summary[task.name] = {
          name: task.name,
          employees: !isGapMode ? task.metEmployees : task.gapEmployees,
          gapEmployees: task.gapEmployees,
          id: task.id,
          type: "task",
          isBubble: true
        };
        return summary;
      },
      {}
    );
  }, [tasks, isGapMode]);

  const generateTreeTasks = (tasks: ITeamRegulationTaskSummary[]) => {
    if (!tasks) return [];

    return tasks.flatMap((task: ITeamRegulationTaskSummary) => {
      const taskEntry = {
        id: task.id,
        rowId: task.id,
        name: task.name,
        taskHierarchy: [task.id],
        gapEmployees: task.gapEmployees,
        employees: task.employees,
        type: "task",
        metEmployees: task.metEmployees
      };

      const competencyEntries = task.competencies.map((competency) => ({
        ...competency,
        taskHierarchy: [task.id, competency.attributeDefinitionId],
        employees: [...competency.gapEmployees.map((c) => ({ ...c, isCompliant: false }))].concat(
          [...competency.metEmployees].map((c) => ({ ...c, isCompliant: true }))
        ),
        rowId: `${task.id}_${competency.attributeDefinitionId}`,
        name: competency.attributeName,
        id: competency.attributeDefinitionId,
        type: "competency"
      }));

      return [taskEntry, ...competencyEntries];
    });
  };

  const filteredTasks = useMemo(() => {
    const filteredTasks = tasks.filter((task) => (isGapMode ? task.gapEmployees.length : true));

    if (!keywords.tasks) return filteredTasks;

    const lowerCaseKeyword = keywords.tasks.toLowerCase();

    return filteredTasks.filter((task) => {
      if (task.name.toLowerCase().includes(lowerCaseKeyword)) {
        return true;
      }

      return task.competencies.some((competency) =>
        competency.attributeName.toLowerCase().includes(lowerCaseKeyword)
      );
    });
  }, [tasks, keywords.tasks, isGapMode]);

  const treeTasks = useMemo(() => generateTreeTasks(filteredTasks), [filteredTasks]);

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    tableRef?.current?.setSortModel(model);
  };

  const handleSortOrderChange = (e: any, sortOrder: any) => {
    onSortModelChange([{ field: "attribute", sort: sortOrder.method }]);
    onSortOrderChange && onSortOrderChange(e, sortOrder);
  };

  const onSwitchToGapMode = (e: ChangeEvent<HTMLInputElement>) => {
    setGapMode({
      ...gapMode,
      tasks: e.target.checked
    });
  };

  const setLocation = (path: any, useNative?: any) => {
    history.push(path + "?" + filter.toUrlParams());

    if (useNative === true) window.history.pushState(null, "", path + "?" + filter.toUrlParams());
    else history.push(path + "?" + filter.toUrlParams());
  };

  const onCloseTaskPeopleListDialog = () => {
    setLocation(basePath + "/" + "tasks", true);
    if (taskPeopleListDialogRef.current) taskPeopleListDialogRef.current.close();
  };

  const onPeopleSelect = (e: any, task: any) => {
    setLocation(
      task.isBubble
        ? `${basePath}/tasks/${task.id}?isBubble=${task.isBubble}`
        : `${basePath}/tasks/${task.id}`,
      true
    );
    if (taskPeopleListDialogRef.current)
      taskPeopleListDialogRef.current.open(task, false, task.type);
  };

  const onGapSelect = (e: any, task: any) => {
    setLocation(basePath + "/tasks/" + task.id + "?isGap=true", true);
    if (taskPeopleListDialogRef.current)
      taskPeopleListDialogRef.current.open(task, true, task.type);
  };

  const onExpandRowChange = (ids: string[]) => {
    setExpandRowIds({ ...expandRowIds, tasks: ids });
  };

  useEffect(() => {
    if (isEmpty(treeTasks)) return;

    const pathSplit = location.pathname.split("/");

    const task = cloneDeep(treeTasks.find((task) => task.id === pathSplit[basePathDepth + 2]));

    if (!task) return;

    const isGap = location.search.includes("isGap=true");
    const isBubble = location.search.includes("isBubble=true");

    if (isBubble) {
      task.employees = task.metEmployees;
    }

    if (taskPeopleListDialogRef.current)
      taskPeopleListDialogRef.current.open(task, isGap, task.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeTasks, basePathDepth]);

  return {
    tableRef,
    sortModel,
    search: keywords.tasks,
    isGapMode,
    classificationsSummary,
    treeTasks,
    setSearch: (value: string) => setKeywords({ ...keywords, tasks: value }),
    onSortModelChange,
    onSwitchToGapMode,
    isLoading,
    taskPeopleListDialogRef,
    onPeopleSelect,
    onCloseTaskPeopleListDialog,
    onGapSelect,
    handleSortOrderChange,
    expandRowIds,
    onExpandRowChange
  };
};

export { useTeamTaskSection };
