import React, { createContext, ReactNode, useContext, useState } from "react";

interface IGapMode {
  competencies: boolean;
  tasks: boolean;
  regulations: boolean;
  trainings: boolean;
}

interface IExpandRowIds {
  tasks: string[];
  regulations: string[];
  trainings: string[];
}

interface IKeywords {
  tasks: string;
  regulations: string;
  trainings: string;
}

interface TeamContextType {
  gapMode: IGapMode;
  setGapMode: (gapMode: IGapMode) => void;
  expandRowIds: IExpandRowIds;
  setExpandRowIds: (expandRowIds: IExpandRowIds) => void;
  keywords: IKeywords;
  setKeywords: (keywords: IKeywords) => void;
}

const TeamContext = createContext<TeamContextType | undefined>(undefined);

export const useTeamContext = () => {
  const context = useContext(TeamContext);
  if (!context) {
    throw new Error("useTeamContext must be used within a TeamProvider");
  }
  return context;
};

interface TeamProviderProps {
  children: ReactNode;
}

export const TeamProvider: React.FC<TeamProviderProps> = ({ children }) => {
  const [gapMode, setGapMode] = useState<IGapMode>({
    competencies: false,
    tasks: false,
    regulations: false,
    trainings: false
  });
  const [expandRowIds, setExpandRowIds] = useState<IExpandRowIds>({
    tasks: [],
    regulations: [],
    trainings: []
  });
  const [keywords, setKeywords] = useState<IKeywords>({
    tasks: "",
    regulations: "",
    trainings: ""
  });

  return (
    <TeamContext.Provider
      value={{
        gapMode,
        setGapMode,
        expandRowIds,
        setExpandRowIds,
        keywords,
        setKeywords
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};
