import { AxiosRequestConfig } from "axios";
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from "react-query";
import axiosClient from "../common/AxiosClient";

interface UseAppQueryOptions<TData = unknown, TError = unknown>
  extends UseQueryOptions<TData, TError> {
  config?: AxiosRequestConfig;
}

export function useAppQuery<TData = unknown, TError = unknown>(
  url: string,
  options?: UseAppQueryOptions<TData, TError>
): UseQueryResult<TData, TError> {
  const queryClient = useQueryClient();
  const queryKey: [string, AxiosRequestConfig | undefined] = [url, options?.config?.params];
  const cachedData: any = queryClient.getQueryData(queryKey);

  return useQuery<TData, TError>({
    queryKey,
    initialData: cachedData,
    queryFn: async () => {
      const res = await axiosClient.get(url, options?.config);

      return res.data;
    },
    ...options
  });
}
