import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Switch } from "klayowebshared";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { DatePicker } from "../../components/DatePicker";
import { SelectMenu } from "../../components/SelectMenu";
import { TextField } from "../../components/TextField";
import { PARTICIPANT_MARKING, PRACTICAL_STATUS } from "../../constants";
import { KLAYO_COLORS } from "../../themes";

export class ParticipantCompetency extends Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);

    this.state = {
      participantAttribute: props.participantAttribute,
      marking: null
    };
  }

  componentDidMount() {
    this.loadMarkingData();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { onCompetencyChange } = this.props;

    if (prevState !== this.state) {
      onCompetencyChange();
    }
  }

  onPracticalAssessmentChange(e: any) {
    const { participantAttribute } = this.state;
    participantAttribute.mark =
      e.target.value &&
      (e.target.value.value || e.target.value.value === PARTICIPANT_MARKING.None.value)
        ? e.target.value.value
        : null;

    if (
      !participantAttribute.mark &&
      participantAttribute.mark !== PARTICIPANT_MARKING.None.value
    ) {
      participantAttribute.comment = null;
      participantAttribute.expiryDate = null;
    }

    if (participantAttribute.mark === PARTICIPANT_MARKING.Pass.value) {
      participantAttribute.expiryDate = null;
    }

    // if mark is null or none, clear comment and expiry date
    if (!participantAttribute.mark) {
      participantAttribute.comment = null;
      participantAttribute.expiryDate = null;
    }

    this.setState({ participantAttribute, marking: e.target.value });
  }

  onDateChange(date: any, valid: any) {
    const { participantAttribute } = this.state;

    participantAttribute.expiryDate = date;
    this.setState({ participantAttribute });
  }

  onCommentChange(e: any) {
    const { participantAttribute } = this.state;

    participantAttribute.comment = e.target.value;
    this.setState({ participantAttribute });
  }

  onParticipatedEnable(e: any) {
    const { participantAttribute } = this.state;
    participantAttribute.attendantStatus = e.target.checked;

    //clear out comment if participant is not attended
    if (!participantAttribute.attendantStatus) {
      participantAttribute.comment = null;
    }
    this.setState({ participantAttribute });
  }

  loadMarkingData() {
    const { participantAttribute } = this.props;

    for (const mark in PARTICIPANT_MARKING) {
      if (
        PARTICIPANT_MARKING[mark as keyof typeof PARTICIPANT_MARKING].value ===
        participantAttribute.mark
      ) {
        this.setState({ marking: PARTICIPANT_MARKING[mark as keyof typeof PARTICIPANT_MARKING] });
      }
    }
  }

  render() {
    const { theme, isAssessment, participant, practical } = this.props;
    const { marking, participantAttribute } = this.state;
    return (
      <ParticipantCompetencyStyled className='klayo_participant-competency'>
        <h2 className='klayo_participant-competency-title'>
          {participantAttribute && participantAttribute.name}
        </h2>
        <Stack flexDirection='row' gap='16px' sx={{ marginTop: "16px" }}>
          {isAssessment ? (
            <SelectMenu
              getItemLabel={(a: any) => a.label}
              label='Assessment (required)'
              value={marking}
              firstValueDefault={false}
              noSelectionError={true}
              dense={true}
              disabled={
                practical.status === PRACTICAL_STATUS[2].value || participant.isAcknowledged
              }
              items={Object.entries(PARTICIPANT_MARKING)}
              onChange={this.onPracticalAssessmentChange.bind(this)}
              sx={{
                width: "180px",
                "& fieldset legend span": {
                  display: "none"
                }
              }}
              clearable={true}
            />
          ) : (
            <Switch
              label='Participated'
              checked={participantAttribute.attendantStatus}
              onChange={this.onParticipatedEnable.bind(this)}
              disabled={
                practical.status === PRACTICAL_STATUS[2].value || participant.isAcknowledged
              }
              sx={{ paddingTop: "24px", justifyContent: "center" }}
            />
          )}

          <div className='klayo_participant-competency-comment-container'>
            <TextField
              label='Comment'
              value={participantAttribute ? participantAttribute.comment : ""}
              clearable={false}
              autoComplete={false}
              onChange={this.onCommentChange.bind(this)}
              placeholder='Optional comment'
              disabled={
                participant.isAcknowledged ||
                (!isAssessment && !participantAttribute.attendantStatus) ||
                (participantAttribute &&
                  participantAttribute.mark === PARTICIPANT_MARKING.None.value)
              }
              sx={{ width: "100%" }}
            />
          </div>
          {isAssessment && participantAttribute.hasExpiryDate && (
            <DatePicker
              sx={{ width: "180px" }}
              theme={theme}
              label='Expiry date (required)'
              onChange={this.onDateChange.bind(this)}
              value={
                participantAttribute && participantAttribute.expiryDate
                  ? new Date(participantAttribute.expiryDate)
                  : null
              }
              allowEmpty={
                participantAttribute && participantAttribute.mark === PARTICIPANT_MARKING.Pass.value
                  ? false
                  : true
              }
              disabled={
                participant.isAcknowledged ||
                (participantAttribute &&
                  (participantAttribute.mark === PARTICIPANT_MARKING.None.value ||
                    participantAttribute.mark === PARTICIPANT_MARKING.Failed.value))
              }
            />
          )}
        </Stack>
      </ParticipantCompetencyStyled>
    );
  }
}

const ParticipantCompetencyStyled = styled.div`
  &.klayo_participant-competency {
    .klayo_participant-competency-title {
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: ${KLAYO_COLORS.GreyGR900};
      margin: 0px;
    }

    .klayo_participant-competency-comment-container {
      flex: 1;
    }
  }
`;
