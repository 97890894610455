import { Box, Grid } from "@mui/material";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import { Delayed, Theme } from "klayowebshared";
import { TeamFilterModel } from "../../common/models/TeamFilterModel";
import { TaskBubbleChart } from "../../components/charts/TaskBubbleChart";
import { Paper } from "../../components/Paper";
import { SectionLoader } from "../../components/SectionLoader";
import { TeamTaskTable } from "../../components/table/TeamTaskTable";
import { TeamFilterSection } from "../../components/TeamFilterSection";
import { Organization } from "../../data/Organization";
import { useTeamTaskSection } from "../../hooks/useTeamTaskSection";
import { TaskPeopleListDialog } from "./TaskPeopleListDialog";

type Props = {
  heading: string;
  theme: any;
  organization: Organization;
  filter: TeamFilterModel;
  groupId?: string;
  onEmployeeSelect: (e: any, employee: any) => void;
  basePath: string;
  basePathDepth: number;
  sortOrder?: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
};

const TeamTasksSection = ({
  heading,
  theme,
  organization,
  filter,
  groupId,
  basePath,
  basePathDepth,
  onEmployeeSelect,
  sortOrder,
  onSortOrderChange
}: Props) => {
  const {
    tableRef,
    sortModel,
    setSearch,
    isGapMode,
    onSwitchToGapMode,
    treeTasks,
    classificationsSummary,
    onSortModelChange,
    isLoading,
    onPeopleSelect,
    onCloseTaskPeopleListDialog,
    onGapSelect,
    taskPeopleListDialogRef,
    search,
    handleSortOrderChange,
    expandRowIds,
    onExpandRowChange
  } = useTeamTaskSection({
    filter,
    groupId,
    basePath,
    basePathDepth,
    sortOrder,
    onSortOrderChange
  });

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => row.taskHierarchy;

  return (
    <>
      <Grid container spacing={4} alignItems='stretch'>
        {isLoading ? (
          <Grid item xs={12}>
            <Box
              sx={{
                padding: "20px",
                background: "white",
                borderRadius: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <SectionLoader />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                title={heading}
                floatingTitle={true}
                padding={0}
                sx={{ height: "350px" }}
                overflow='visible'
              >
                <TaskBubbleChart
                  theme={theme}
                  attributes={classificationsSummary}
                  onAttributeSelect={isGapMode ? onGapSelect : onPeopleSelect}
                  sx={{ height: "350px" }}
                  {...(isGapMode
                    ? {
                        bubbleColor: Theme.getStyleVar("--torch-red-tr-050"),
                        bubbleTextColor: Theme.getStyleVar("--grey-gr-900")
                      }
                    : {})}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TeamFilterSection
                search={search}
                isGapMode={isGapMode}
                onSearch={(keyword: string) => setSearch(keyword)}
                searchPlaceholder='Search task or competency'
                onSwitchToGapMode={onSwitchToGapMode}
                sortOrder={sortOrder}
                onSortOrderChange={handleSortOrderChange}
                theme={theme}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Delayed>
                <TeamTaskTable
                  rows={treeTasks || []}
                  tree
                  theme={theme}
                  ref={tableRef}
                  showHeaderFromBreakpoint='sm'
                  columns={[
                    {
                      type: TeamTaskTable.columns.summaryWithPeople,
                      visibleToBreakpoint: "sm"
                    },
                    {
                      type: TeamTaskTable.columns.attribute,
                      label: "Tasks",
                      visibleFromBreakpoint: "sm"
                    },
                    {
                      type: TeamTaskTable.columns.attributeEmployees,
                      visibleFromBreakpoint: "sm"
                    },
                    { type: TeamTaskTable.columns.gapEmployees, visibleFromBreakpoint: "sm" }
                  ]}
                  onPeopleSelect={onPeopleSelect}
                  onGapSelect={onGapSelect}
                  showProficiency={organization && organization.showProficiency}
                  sortModel={sortModel}
                  onSortModelChange={onSortModelChange}
                  noRowsMessage='Nothing here yet!'
                  getTreeDataPath={getTreeDataPath}
                  keyword={search}
                  onExpandRowChange={onExpandRowChange}
                  expandedRowIds={expandRowIds.tasks}
                />
              </Delayed>
            </Grid>
          </>
        )}
      </Grid>
      <TaskPeopleListDialog
        ref={taskPeopleListDialogRef}
        organization={organization}
        theme={theme}
        onClose={onCloseTaskPeopleListDialog}
        onEmployeeSelect={onEmployeeSelect}
      />
    </>
  );
};
export { TeamTasksSection };
